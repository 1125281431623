
collectionSliders = function () {
    document.addEventListener('livewire:initialized', () => {
        document.querySelectorAll(".collection-slider").forEach((slider) => {
            new Splide(slider, {
                type: "loop",
                perPage: 1,
                gap: 30,
                perMove: 1,
                rewind: true,
                arrows: true,
                autoplay: false,
                interval: 5000,
                pagination: true,
                breakpoints: {
                    1440: {
                        perPage: 1,
                    },
                    1024: {
                        perPage: 1,
                    },
                    768: {
                        perPage: 1,
                    },
                    600: {
                        perPage: 1,
                    },
                },
                video: {
                    loop: true,
                    mute: true,
                    autoplay: true,
                    hideControls: true,
                    disableOverlayUI: true,
                    playerOptions: {
                        youtube: {},
                        vimeo: {},
                        htmlVideo: {
                            autoplay: true,
                            controls: false,
                            width: '100vw',
                            height: '100vh',
                            muted: true,
                            preload: true,
                            playsInline: true,
                            poster: '/img/bmc-logo-blk-trans-square.png'
                        },
                    }
                }
            }).mount(window.splide.Extensions);
        });

        // let videoContainer = document.querySelectorAll('.splide__video');
        // for (let i = 0; i < videoContainer.length; i++) {
        //     if (videoContainer[i]) {
        //         videoContainer[i].addEventListener('click', function (e) {
        //             e.preventDefault()
        //             e.stopPropagation()
        //             let url = this.parentElement.getAttribute('data-url');
        //             document.location.href = url;
        //         });
        //     }
        // }
    });
}
tripleSliders = function  () {
    document.querySelectorAll(".triple-slider").forEach((slider) => {
        new Splide(slider, {
            type: "loop",
            perPage: 3,
            gap: 30,
            perMove: 1,
            speed: 0,
            rewind: true,
            arrows: true,
            autoplay: false,
            interval: 5000,
            pagination: true,
            breakpoints: {
                1440: {
                    perPage:3,
                    perMove: 1
                },
                1024: {
                    perPage: 3,
                    perMove: 1,
                },
                768: {
                    perPage: 2,
                    perMove: 1,
                },
                600: {
                    perPage: 1,
                    perMove: 1,
                },
            },
        }).mount();
    });
}

let productSlides = []
let currentIndex = 0
let currentKey = 0

productSlider = function () {
   document.querySelectorAll(".product-slider").forEach((slider, key) => {
       currentKey = key
       productSlides[key] = new Splide(slider, {
           type: 'slide',
           start: currentIndex,
           perPage: 6,
           gap: 0,
           perMove: 1,
           rewind: false,
           pagination: false,
           breakpoints: {
               1350: {
                   perPage: 3,
                   perMove: 1,
                   padding: {
                       left: 20,
                       right: 20,
                   },
               },
               768: {
                   perPage: 2,
                   perMove: 1,
                   padding: {
                       left: 10,
                       right: 10,
                   },
               },
               600: {
                   perPage: 1,
                   perMove: 1,
                   padding: {
                       left: 0,
                       right: 0,
                   },
               },
           },
       }).mount();
   });

    productSlides[currentKey].on('move', function(newIndex, oldIndex, destIndex) {
        if((newIndex + productSlides[currentKey].options.perPage) >= productSlides[currentKey].length-1) {
            Livewire.dispatch('getItemsNext', {key: currentKey, index: newIndex})
        }
    });

   // console.log(productSlides[currentKey]);

    Livewire.on('resetIndex', function(slideIndex) {
        currentIndex = slideIndex
    })


}


let pickSlides = []
let currentPickIndex = 0
let currentPickKey = 0

staffPicks = function () {
    document.querySelectorAll(".staff-picks-slider").forEach((slider, key) => {
        currentPickKey = key
        pickSlides[key] = new Splide(slider, {
            type: 'slide',
            start: currentPickIndex,
            perPage: 6,
            gap: 0,
            perMove: 1,
            rewind: false,
            pagination: false,
            breakpoints: {
                1350: {
                    perPage: 3,
                    perMove: 1,
                    padding: {
                        left: 20,
                        right: 20,
                    },
                },
                768: {
                    perPage: 2,
                    perMove: 1,
                    padding: {
                        left: 10,
                        right: 10,
                    },
                },
                600: {
                    perPage: 1,
                    perMove: 1,
                    padding: {
                        left: 0,
                        right: 0,
                    },
                },
            },
        }).mount();
    });

    pickSlides[currentPickKey].on('move', function(newIndex, oldIndex, destIndex) {
        if((newIndex + pickSlides[currentPickKey].options.perPage) >= pickSlides[currentPickKey].length-1) {
            Livewire.dispatch('getPickItemsNext', {key: currentPickKey, index: newIndex})
        }
    });

    // console.log(pickSlides[currentPickKey]);

    Livewire.on('resetPickIndex', function(slideIndex) {
        currentPickIndex = slideIndex
    })


}

window.addEventListener('scrollToTop', function() {
    window.scrollTo(0, 0);
})

require('hammerjs')

// swipe gallery functionality (staff galleries, customer showes, product gallery)
const observer = new MutationObserver(function (mutations, mutationInstance) {
    // console.log('hammer init')
    const swipeElement = document.getElementById('swipe-gallery');
    if (swipeElement) {
        initSwipe(swipeElement);
        mutationInstance.disconnect();
    }
    const modalSwipeElement = document.getElementById('modal-swipe-gallery');
    if (modalSwipeElement) {
        // console.log('modal swipe init')
        initSwipe(modalSwipeElement);
        mutationInstance.disconnect();
    }
});

observer.observe(document, {
    childList: true,
    subtree:   true
});

function initSwipe (swipeElement) {
    if (swipeElement) {
        let hammerWatch = new Hammer(swipeElement, {domEvents: true});
        hammerWatch.on("swipeleft swiperight panleft panright", function (ev) {
            ev.preventDefault();
            if (ev.type == "swipeleft" || ev.type == "panleft") {
                window.dispatchEvent(new Event("swipeleft"));
            }
            if (ev.type == "swiperight" || ev.type == "panright") {
                window.dispatchEvent(new Event("swiperight"));
            }
        });
    }
}
